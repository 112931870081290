import { useEffect } from "react";
import fileDownload from "js-file-download";
import Button from "../shared/Button";
import axios from "../../axios";
import Loading from "../layout/Loading";
import { useState } from "react";
import { useStore } from "../designer/Store/zustandStore";
import { useProject } from "../../hooks/useQueries";
import { isEmpty } from "lodash";
import { selectionStore } from "../../stores/userStore";
import { useConfigurator } from "../../hooks/useQueries";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";


export default function SelectionHeader({ ventilationUnitId, toggleConfigMenu,onPrintClick, setSelectedTechnicalTab }) {
  const { addToProject, updateProjectItem } = useProject();
  const [printing, setPrinting] = useState(false);
  const [addingToProject, setAddingToProject] = useState(false);
  const [updatingProjectItem, setUpdatingProjectItem] = useState(false);
  const [ drawings, setRequestDrawings] = useStore((state) => [state.drawings, state.setRequestDrawings]);
  const { referrer } = useConfigurator();

  const print = (printType) => {
    setSelectedTechnicalTab("Designer")
    setRequestDrawings(true);
    setPrinting(true);
  };

  const onAddToProject = () => {
    setSelectedTechnicalTab("Designer");
    setRequestDrawings(true);
    setAddingToProject(true);
  }

  const onUpdateProjectItem = () => {
    setSelectedTechnicalTab("Designer")
    setRequestDrawings(true);
    setUpdatingProjectItem(true);
  }

  useEffect(() => {

    if (printing) {
      const printRequest = {
        Id: ventilationUnitId,
        PrintType: 0,
        Referrer: "Selector",
        Culture: "en-GB",
        Drawings: useStore.getState().drawings,
      };
      axios
        .post(`/Print/Print`, printRequest, {
          responseType: "blob",
        })
        .then((response) => {
          fileDownload(response.data, ventilationUnitId + ".pdf");
          setPrinting(false);
        })
        .catch(function (error) {
          setPrinting(false);
        });
    }

    if(addingToProject)
    {
      addToProject({ ResultId: ventilationUnitId, Drawings:useStore.getState().drawings  })
      setAddingToProject(false);
    }

    if(updatingProjectItem)
    {
      updateProjectItem({ ResultId: ventilationUnitId, Drawings:useStore.getState().drawings  });
      setUpdatingProjectItem(false);
    }
  }, [drawings]);


  return (
    <div className="relative flex py-2 gap-1.5 ml-3 text-sm">
      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md" onClick={onPrintClick} disabled={isEmpty(ventilationUnitId)}>
        Print
      </Button>
      {referrer != "Project" && (
        <Button
          text="Add to Project"
          buttoncolor="primary"
          buttonSize="xs"
          onClick={() => onAddToProject()}
          disabled={isEmpty(ventilationUnitId)}
        ></Button>
      )}
      {referrer == "Project" && (
        <label className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-red-700 bg-red-50 ring-red-600/10"> Project Mode</label>
        // <Button text="Update Project Item" buttoncolor="danger" buttonSize="xs" onClick={()pty(ventilationUnitId) => onUpdateProjectItem()} disabled={isEmpty(ventilationUnitId)}></Button>
      )}

      <Loading cssClass="justify-end float-right ml-auto w-5 mr-3" isCalculating={printing} />
    </div>
  );
}
